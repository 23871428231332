import React, { Component } from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import "./static/styles/trainTComponent.css"

const divStyles = {
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `auto`,
  margin: `auto`,
  gridGap: `1rem`,
}

const paraStyles = {
  fontSize: `0.9rem`,
  color: `rgb(80,80,80)`,
  paddingRight: `0.7rem`,
  textAlign: `justify`,
  fontWeight: `500`,
}

const listStyles = {
  fontSize: `0.9rem`,
  color: `rgb(80,80,80)`,
  paddingRight: `0.5rem`,
  textAlign: `justify`,
  fontWeight: `500`,
}

const curriculumStyles = {
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `auto`,
  paddingTop: `0rem`,
  paddingLeft: `0.0rem`,
  gridGap: `0.7rem`,
}

class TeachersPageComp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      prodCode: "sku_FkjyqVPNE4TaIC",
    }
  }

  render() {
    return (
      <div className="mainTeachers__pageComp">
        <div style={divStyles} className="divStyles__teachers">
          <h2
            style={{
              textTransform: `uppercase`,
              fontSize: `1.8rem`,
              paddingTop: `1rem`,
            }}
          >
            Teacher Training
          </h2>
          <span>
            <h2 style={{ textTransform: `uppercase`, fontSize: `1.6rem` }}>
              200hr Yoga Training
            </h2>
            <h4>3mth/ 6mth/ 12mth track</h4>
          </span>
          <span>
            <h4>Upcoming Dates</h4>
            <p>Saturday, November 09th 2020 - October 2021</p>
          </span>
          <span>
            <h4>Times:</h4>
            <p
              style={{
                fontSize: `0.9rem`,
                color: `rgb(80,80,80)`,
                paddingRight: `0.7rem`,
                textAlign: `justify`,
                fontWeight: `500`,
                lineHeight: `1.6rem`,
              }}
            >
              Weekly
              <br />
              Wed: 6pm-9.30pm (6mth only) <br />
              Thu: 6pm-9.15pm (6mth & 12mth)
              <br />
              Sat: 3pm-7.30pm (6mth only)
              <br />
              Sun: 1pm-4.30pm (6mth & 12mth)
              <br />
              <p
                style={{
                  fontSize: `0.9rem`,
                  color: `rgb(80,80,80)`,
                  paddingRight: `0.7rem`,
                  paddingTop: `0.3rem`,
                  textAlign: `justify`,
                  fontWeight: `500`,
                  lineHeight: `1rem`,
                }}
              >
                Our 3mth track schedules are flexible. Please contact us for
                more information :)
              </p>
            </p>
          </span>
          <span>
            <h4 style={{ textTransform: `uppercase` }}>
              Become a Competent Teacher
            </h4>
            <p style={paraStyles}>
              Share what you are passionate about with others.This course will
              give you a solid base to build on, and expound the fundamentals of
              teaching yoga. Whether your goal is to start teaching or to become
              a more complete yoga practitioner whilst deepening your practice,
              this course is for you!
            </p>
            <p style={paraStyles}>
              This well respected course is certified by the Yoga Alliance.
              Learn to guide a room and lead your students on their own
              emotional and physical yoga journey. If you prefer specific
              pre-requisites, we would usually advise: if you have practiced
              yoga for atleast a year already, then you are ready! Delve deeply
              into learning the asanas but also into self-development. Tap into
              your personal strength to attain your personal goals when you
              learn-to-teach, with us.
            </p>
            <p style={paraStyles}>
              You also learn how to manage energy and becoming a more well
              rounded individual. Creating better connected, community minded
              individuals is a focus for us. You will be guided into becoming
              more aware of the workings of your body to a greater connection
              with your purpose. This invites the spark of change, growth and
              inspiration to take seed - for yourself and others in your micro
              and macro community circle. From the history and philosophy behind
              yoga to being able to step into a room confident in yourself and
              your deep knowledge we think you ae going to love coming on this
              journey with us.
            </p>
          </span>
          <span style={curriculumStyles} className="cirriculumStyle" >
            <h3 style={{ textTransform: `uppercase` }}>Curriculum</h3>
            <span>
              <h4 style={{ color: `rgb(80,78,78)` }}>
                Techniques, Training and Practice
              </h4>
              <span style={listStyles} className="listHolder">
                <li>Asanas: Deep dive into yoga poses</li>
                <li>Pranayamas & Meditations </li>
                <li>Introduction to gentle yoga</li>
                <li>Promoting General WellBeing</li>
                <li>Modifications for pregnancy</li>
                <li>Traditional Yoga Techniques</li>
                <li>Safe exploration of 2nd Level Poses</li>
              </span>
            </span>
            <span>
              <h4 style={{ color: `rgb(80,78,78)` }}>Teaching Methodology</h4>
              <span style={listStyles} className="listHolder">
                <li>How to teach private sessions</li>
                <li>Addressing specific needs</li>
                <li>Teaching 101: Qualities of a teacher</li>
                <li>Finding your own style</li>
                <li>Student Learning Process</li>
                <li>Business Aspects: Marketing & Legal</li>
              </span>
            </span>
            <span>
              <h4 style={{ color: `rgb(80,78,78)` }}>Anatomy & Physiology</h4>
              <span style={listStyles} className="listHolder">
                <li>Human Physical Anatomy & Physiology</li>
                <li>Energy Anatomy (Chakras and Nadis)</li>
              </span>
            </span>
            <span>
              <h4 style={{ color: `rgb(80,78,78)` }}>
                Yoga Philosophy, Ethics and Lifestyle
              </h4>
              <span style={listStyles} className="listHolder">
                <li>Study of Philosophy & Traditional Texts</li>
                <li>Non Violence, Dharma & Karma </li>
                <li>Ethics for Yoga Teachers</li>
                <li>Value of teaching yoga as a service</li>
              </span>
            </span>
            <span>
              <h4 style={{ color: `rgb(80,78,78)` }}>Practicum</h4>
              <span style={listStyles} className="listHolder">
                <li>Practice Leading a Small Group</li>
                <li>Allowing and giving feedback</li>
                <li>Observing other teachers</li>
                <li>Assisting another teacher's students</li>
              </span>
            </span>
          </span>
          <span>
            <h3 style={{ textTransform: `uppercase` }}>Price</h3>
            <span>
              <p style={paraStyles}>
                Regular: <span style={{ fontWeight: `600` }}>$3800</span> (US
                Dollars) or,
                <br /> Earlybird Price of <span>$3500</span> (US Dollars) if
                paid, in full by 20 Nov 2019
              </p>
              <p style={paraStyles}>
                Payment plan available: <br />7 monthly payments of{" "}
                <span>$500</span>
                <br />
                Please contact us to set this up
              </p>
            </span>
          </span>
          <Link
            to="/checkout"
            state={{ productCode: this.state.prodCode }}
            style={{ display: `grid` }}
          >
            <Button
              style={{
                backgroundColor: `#FF5722`,
                border: `none`,
                fontWeight: `600`,
                height: `2.4rem`,
                marginTop: `0.5rem`,
                marginBottom: `0.5rem`,
                width: `85%`,
              }}
              className="teachPgCompButton"
            >
              Register Now
            </Button>{" "}
          </Link>
        </div>
      </div>
    )
  }
}

export default TeachersPageComp
