import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TeachersPageComp from "../components/teachersPageComp"
import sideImageL from "../images/siderailimg.png"
import sideImageR from "../images/siderailimg.png"

import "../components/static/styles/trainTPage.css"

export default class TrainTeachers extends Component {
  render() {
    return (
      <Layout>
        <SEO title="200hr Yoga Teacher Training" />
        <div className="mainTeachersTrain">
          <img
            src={sideImageL}
            alt="sideBanner"
            className="sideImageLTeachers"
          />
          <TeachersPageComp />
          <img
            src={sideImageR}
            alt="sideBanner"
            className="sideImageRTeachers"
          />
        </div>
      </Layout>
    )
  }
}
